@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Krub&display=swap');

html{
  scroll-behavior:smooth;
  margin: 0;
  padding: 0;
}
body {
  
  font-family: 'Krub', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: whitesmoke;
  background-color: rgb(15 23 42);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline-item::after {
  content: '';
  position: absolute;
  left: 6px; /* Aligns with the center of the logo */
  top: 0;
  bottom: 0;
  width: 2px; /* Line thickness */
  background-color: #ddd; /* Line color */
}
